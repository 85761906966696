.meta-tooltip {
  border-radius: $t-space-border-radius;
  padding: $t-space-unit-smallest $t-space-unit-base;

  &.type-dark {
    background-color: $t-color-neutral-07;

    &.place-top {
      margin-bottom: $t-space-unit-smaller;

      &:after {
        border-left: $t-space-unit-smaller solid transparent;
        border-right: $t-space-unit-smaller solid transparent;
        border-top: $t-space-unit-small solid $t-color-neutral-07;
      }
    }

    &.place-bottom {
      margin-top: $t-space-unit-smaller;

      &:after {
        border-bottom: $t-space-unit-small solid $t-color-neutral-07;
        border-left: $t-space-unit-smaller solid transparent;
        border-right: $t-space-unit-smaller solid transparent;
      }
    }

    &.place-left {
      margin-right: $t-space-unit-smaller;

      &:after {
        border-bottom: $t-space-unit-smaller solid transparent;
        border-left: $t-space-unit-small solid $t-color-neutral-07;
        border-top: $t-space-unit-smaller solid transparent;
        margin-top: $t-space-unit-smaller - $t-space-unit-base; //-8px
      }
    }

    &.place-right {
      margin-left: $t-space-unit-smaller;

      &:after {
        border-bottom: $t-space-unit-smaller solid transparent;
        border-right: $t-space-unit-small solid $t-color-neutral-07;
        border-top: $t-space-unit-smaller solid transparent;
        margin-top: $t-space-unit-smaller - $t-space-unit-base; //-8px
      }
    }
  }
}
