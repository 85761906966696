
.meta-modal {
  background-color: $t-color-base-white;
  border-radius: $t-space-border-radius;
  margin: (4 * $t-space-unit-base);

  &:focus {
    outline: none;
  }

  .meta-modal__header {
    align-items: center;
    border-bottom: 1px solid $t-color-neutral-04;
    display: flex;
    flex-flow: row;
    height: (4 * $t-space-unit-base);
    justify-content: space-between;
    padding: 0 $t-space-unit-large;

    .meta-modal__header-title {
      color: $t-color-neutral-07;
      font-size: $t-type-size-heading-3;
      line-height: $t-type-size-line-height-heading-3;
    }
  }

  .meta-modal__content {
    height: 100%;
    padding: ($t-space-unit-large);
  }

  .meta-modal__footer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    padding: ($t-space-unit-large);
  }
}

.meta-modal-overlay {
  background-color: rgba($t-color-base-dark-blue-6, 0.85);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
