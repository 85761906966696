$bar-padding: ($t-space-unit-large * 2);
$half-of-small: $t-space-unit-small / 2;

.meta-action-bar {
  background-color: $t-color-base-white;
  border-bottom: 1px solid $t-color-base-dark-blue-3;
  display: flex;
  flex-direction: row;
  // 80px; matched height meta-nav to ensure height aligns
  height: $t-space-unit-largest * 2 + $t-space-unit-smaller;
  justify-content: space-between;
  min-height: $t-space-unit-largest * 2;
  padding: $t-space-unit-base $bar-padding $t-space-unit-small $bar-padding;
  position: sticky;
  top: 0;
  z-index: 500;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__text {
      font-weight: normal;
      margin: 0;
    }
  }

  &__back-button {
    @extend %reset-button;
    align-items: center;
    align-self: center;
    background: transparent;
    border-radius: 50%;
    display: flex;
    height: $t-space-unit-large;
    justify-content: center;
    margin: 0 $half-of-small 0 (-$half-of-small);
    width: $t-space-unit-large;

    &:hover {
      background: $t-color-neutral-03;
    }

    &:active {
      background: $t-color-neutral-04;
    }

    &__back-button-icon {
      fill: $t-color-base-blue;
    }
  }
}

.meta-action-bar__with-back-button {
  padding-left: 24px;
}
