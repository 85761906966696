.meta-textarea {
  position: relative;
  width: fit-content;

  &__textarea {
    @extend %input;
  }

  &__label {
    @extend %label;
  }

  &__scrim {
    background-color: $t-color-background;
    display: flex;
    flex-grow: 1;
    height: $t-space-unit-base + 6px;
    left: $t-space-unit-smaller;
    opacity: 0;
    position: absolute;
    top: 13px;
    width: calc(100% - 32px);
    z-index: 1;
  }

  > .meta-validation {
    @extend %validation-spacing;
  }

  &.not-resizable {
    width: unset;

    textarea {
      resize: none;
    }
  }
}
