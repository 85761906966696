.meta-breadcrumbs {
  color: $t-color-base-dark-blue-4;
  display: flex;
  flex-direction: column;
  font-size: $t-type-size-sub;
  justify-content: center;

  .meta-breadcrumbs__title {
    margin: 0;
  }

  .meta-breadcrumbs__breadcrumbs {

    .meta-breadcrumbs__breadcrumbs__link {
      color: $t-color-base-dark-blue-4;
      cursor: pointer;

      &:hover {
        color: $t-color-base-blue;
        text-decoration: underline;
      }

      &:active {
        color: $t-color-base-dark-blue;
        text-decoration: underline;
      }
    }
  }
}
