.meta-file-drop {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  // variable exceptions in this block to get the 1px border
  // around the dropzone to match mocks
  &__dropzone {
    background-color: $t-color-neutral-01;
    border: 15px dashed $t-color-neutral-05;
    clip-path: inset(14px 14px round $t-space-border-radius);
    margin: -14px;
    padding: $t-space-unit-largest;
    text-align: center;
    width: calc(100% + #{$t-space-unit-larger});

    + * {
      margin-top: $t-space-unit-largest;
    }
  }

  &--active {

    .meta-file-drop__dropzone {
      background-color: $t-color-neutral-02;
    }
  }
}
