.meta-icon {
  fill: $t-color-neutral-06;

  .jsx-parser {
    display: flex;
    height: inherit;
    width: inherit;

    svg {
      height: inherit;
      width: inherit;
    }
  }

  &.hide-hover-text {
    pointer-events: none;
  }

  &.meta-icon--base {
    height: $t-space-unit-base;
    width: $t-space-unit-base;
  }

  &.meta-icon--small {
    height: $t-space-unit-small;
    width: $t-space-unit-small;
  }

  &.meta-icon--smaller {
    height: $t-space-unit-smaller;
    width: $t-space-unit-smaller;
  }

  &.meta-icon--smallest {
    height: $t-space-unit-smallest;
    width: $t-space-unit-smallest;
  }

  &.meta-icon--large {
    height: $t-space-unit-large;
    width: $t-space-unit-large;
  }

  &.meta-icon--larger {
    height: $t-space-unit-larger;
    width: $t-space-unit-larger;
  }

  &.meta-icon--largest {
    height: $t-space-unit-largest;
    width: $t-space-unit-largest;
  }
}
