.list,
ol,
ul {
  color: $t-color-neutral-07;
  font-size: $t-type-size-paragraph;
  font-weight: $t-type-weight-paragraph;
  line-height: $t-type-size-line-height-paragraph;
  margin: $t-space-unit-base 0;
  padding-inline-start: $t-space-unit-base;

  li {
    padding: 0;

    > ul,
    > ol {
      margin: $t-space-unit-smallest 0;
      padding-inline-start: $t-space-unit-larger;
    }
  }
}


ol  {

  li {
    padding-inline-start: $t-space-unit-smallest;
  }
}

.list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
