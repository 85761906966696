.meta-select {
  position: relative;
  width: 100%;

  &:focus {
    outline: none;

    .meta-select__control {
      &:focus {
        border-bottom-color: $t-color-primary-03;
        border-bottom-width: 2px;
        outline: none;
      }
    }
  }

  &__label {
    @extend %label;
    color: $t-color-primary-03;

    &.is-active {
      max-width: 100%;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      top: $t-space-s;
      transform: translate3d(0, -20%, 0);
      white-space: nowrap;
    }
  }

  > .meta-validation {
    @extend %validation-spacing;
  }

  &__dropdown__icon {
    fill: $t-color-neutral-06;
    margin: $t-space-m;
    transition: 150ms linear all;

    &.is-open {
      transform: rotate(180deg);
    }
  }

  .meta-select__input {
    height: 100%;
    width: 100%;

    input {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;

      &::placeholder {
        color: $t-color-neutral-06;
        font-weight: $t-type-weight-regular;
      }
    }
  }

  .meta-select__control {
    border: 1px solid $t-color-border-default;
    border-bottom: 1px solid $t-color-neutral-05;
    border-radius: $t-space-border-radius $t-space-border-radius 0 0;
    flex-wrap: nowrap;
    margin-top: $t-space-m;

    &--is-focused {
      border-bottom-color: $t-color-primary-03;
      border-bottom-width: 2px;
      box-shadow: none;
      margin-bottom: -1px;
      outline: none;
    }

    &:hover {
      border-left-color: $t-color-border-default;
      border-right-color: $t-color-border-default;
      border-top-color: $t-color-border-default;
    }
  }

  &.input-invalid {
    &__error {
      .meta-select__control {
        @extend %error-input;

        .meta-select__label {
          color: $t-color-critical-02;
        }

        &--is-focused {
          border-bottom-color: $t-color-primary-03;

          .meta-select__label {
            color: $t-color-primary-03;
          }
        }
      }
    }

    &__warning {
      .meta-select__control {
        @extend %warning-input;

        .meta-select__label {
          color: $t-color-warning-03;
        }

        &--is-focused {
          border-bottom-color: $t-color-primary-03;

          .meta-select__label {
            color: $t-color-primary-03;
          }
        }
      }
    }
  }

  // written like this because we need the specificity
  .meta-select__value-container {
    max-width: calc(100% - #{$t-space-xxxl + $t-space-xs});
    overflow: visible;
    padding: $t-space-l $t-space-m;

    > div {
      margin: 0;
      max-width: 100%;
      padding: 0;
    }

    &--has-value {
      padding: $t-space-xl $t-space-m $t-space-s $t-space-m;

      .meta-select {
        &__single-value {
          margin-top: $t-space-s;
        }

        &__multi-value {
          border-radius: $t-space-l;
          margin: 2px;

          &__label {
            font-size: $t-type-size-sub;
            line-height: $t-type-size-line-height-sub;
            padding: 0 $t-space-xs 0 $t-space-s;
          }

          &__remove {
            border-radius: 0 $t-space-l $t-space-l 0;
            color: $t-color-neutral-05;
            padding: 0 $t-space-xs 0 0;

            &:hover {
              background-color: $t-color-critical-01;
            }
          }

          &:hover {
            background-color: $t-color-critical-01;
          }

          &:active {
            background-color: $t-color-critical-01;

            .meta-select__multi-value__remove {
              color: $t-color-critical-03;
            }
          }
        }
      }

      &.meta-select__value-container--is-multi {
        padding-left: 10px;
      }
    }
  }

  &__menu {
    margin: $t-space-xs 0;
    z-index: 3 !important; // sass-lint:disable-line no-important

    .meta-select__option {
      background-color: $t-color-neutral-01;
      cursor: pointer;

      &--is-focused {
        background-color: rgba($t-color-primary-03, 0.04);
      }

      &--is-selected {
        background-color: $t-color-primary-03;
        color: $t-color-neutral-01;
      }

      &:active {
        background-color: $t-color-primary-01;
      }
    }
  }

  &__option {
    color: $t-color-neutral-06;
    line-height: $t-type-size-line-height-body-condensed;
  }

  &.no-label {
    .meta-select__single-value {
      margin: 0;
    }
  }
}
