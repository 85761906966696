$transition-speed: 250ms;
$nav-border: 1px solid rgba($t-color-neutral-04, 0.3);

.meta-nav {
  background-color: $t-color-neutral-07;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  transition: max-width $transition-speed ease-in-out,
  max-height $transition-speed ease-in-out; // sass-lint:disable-line indentation, max-line-length

  .meta-icon {
    fill: $t-color-neutral-04;
  }

  &.meta-nav--is-expanded {

    .is-expanded {
      > .meta-tree__list__item--trigger {

        .meta-nav-icon {

          &--catalog {
            fill: $t-color-product-catalog;
          }

          &--customers {
            fill: $t-color-product-customers;
          }

          &--money {
            fill: $t-color-product-money;
          }

          &--channels {
            fill: $t-color-product-channels;
          }

          &--supply-chain {
            fill: $t-color-product-supply-chain;
          }
        }
      }
    }
  }

  // METATREE OVERRIDES SPECIFIC TO METANAV
  .meta-tree__list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .meta-tree__list__item {
    color: $t-color-neutral-04;

    &--link,
    &--trigger {
      cursor: pointer;
      padding: $t-space-unit-smaller $t-space-unit-large;
      width: 100%;

      &:hover {
        background-color: lighten($t-color-neutral-07, 6%);
        border-radius: 0;
        padding: $t-space-unit-smaller $t-space-unit-large;
        width: 100%;
      }

      span {
        color: $t-color-neutral-04;
        font-size: $t-space-unit-larger / 2;
      }

      > .meta-tree__list__item__content {
        line-height: $t-type-size-line-height-heading-4;
      }
    }
  }

  // OVERRIDE: MAKE WHITE AND BOLD
  .meta-tree__list__item--parent {

    &.is-expanded {

      > .meta-tree__list__item--trigger {

        span {
          color: $t-color-neutral-01;
          font-weight: $t-type-weight-medium;
        }
      }
    }
  }

  // EXTRA PADDING ON TOP LEVEL
  .meta-tree--level-0 {
    padding: $t-space-unit-large 0;

    > .meta-tree__list__item {

      > .meta-tree__list__item--link,
      > .meta-tree__list__item--trigger {

        > .meta-tree__list__item__content {
          padding-left: $t-space-unit-smallest;
        }
      }
    }
  }

  // ADD SIDEBAR STYLES TO NESTED CHILDREN
  .meta-tree--level-2 {

    .meta-tree__list__item--link {
      padding-bottom: 0;
      padding-top: 0;

      .meta-tree__list__item__content {
        border-left: $nav-border;
        padding: $t-space-unit-smaller $t-space-unit-base;
      }
    }

    li:first-child {

      .meta-tree__list__item__content {
        margin-top: $t-space-unit-smaller;
        padding-top: 0;
      }
    }

    li:last-child {

      .meta-tree__list__item__content {
        margin-bottom: $t-space-unit-smaller;
        padding-bottom: 0;
      }
    }
  }

  .meta-input {
    align-items: center;
    display: flex;
  }

  // SEARCH INPUT BOX
  .meta-input__input {
    background-color: transparent;
    border: 0;
    border-bottom: $nav-border;
    border-radius: 0;
    color: $t-color-neutral-04;
    line-height: $t-type-size-line-height-heading-4;
    margin: 0;
    padding: $t-space-unit-small;
    padding-left: $t-space-unit-larger * 2;

    &::placeholder {
      color: $t-color-neutral-04;
    }
  }
}

.meta-search {

  &__input {

    &__search-icon {
      margin-left: $t-space-unit-larger;
      position: absolute;
    }

    &__search-button {
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 5; // make sure over input element

      .meta-search__input__search-icon {
        margin: 0;
      }
    }

    &__clear-icon {
      margin: 0 $t-space-unit-small;
      position: absolute;
      right: 0;
    }
  }


  &__results {
    height: calc(100% - 50px); // offset search input height
    overflow-y: scroll;
  }

  &__result {
    color: $t-color-neutral-04;
    display: flex;
    flex-direction: column;
    padding: $t-space-unit-base;

    &--selected {
      background-color: lighten($t-color-neutral-07, 4%);
    }

    &:hover {
      background-color: lighten($t-color-neutral-07, 6%);
      cursor: pointer;
    }

    p {
      color: $t-color-neutral-04;
      font-size: $t-type-size-sub;
      margin: 0;

      &.meta-search__result__header {
        font-size: $t-type-size-paragraph;
        font-weight: $t-type-weight-medium;
      }
    }
  }
}

.meta-nav__header {
  border-bottom: $nav-border;
  display: flex;
  // 80px; matched height with action bar to ensure height aligns
  height: $t-space-unit-largest * 2 + $t-space-unit-smaller;
  justify-content: space-between;
  padding: $t-space-unit-large;

  &__link {
    display: block;
    line-height: $t-space-unit-smaller;
  }
}

.meta-nav__search {
  position: relative; // necessary for collapsed positioning

  &:nth-last-child(2) {
    flex: 1 1 100%;
  }
}

.meta-nav__content {
  flex-grow: 1;
}

// SCROLL OVERFLOW WITH STYLED SCROLLBAR
.meta-nav__search {
  flex-shrink: 0;
  overflow: hidden;
}

.meta-nav__content,
.meta-search__results {
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: $t-space-unit-small / 2;
  }

  &::-webkit-scrollbar-thumb  {
    background-color: rgba($t-color-neutral-04, 0.3);
    border-radius: $t-space-border-radius;

    &:hover {
      background-color: rgba($t-color-neutral-04, 0.6);
      cursor: pointer;
    }
  }
}

.meta-nav__footer {
  border-top: $nav-border;
  display: flex;
  flex-direction: column;
}

.meta-search__input__clear-icon,
.expand-collapse-button {

  &.meta-btn {
    background-color: transparent;
    padding: 0;
  }
}


@import "./styles-small.scss";
@import "./styles-medium.scss";
