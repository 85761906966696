.rt-tr-group {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

}

.rt-td {
  align-items: center;
  display: flex;
  padding: $t-space-unit-smaller $t-space-unit-base;
}
