.meta-radio {
  cursor: pointer;
  display: flex;

  &__vertical {
    flex-direction: column;
    padding-left: $t-space-unit-smaller;
  }

  &__horizontal {
    flex-wrap: wrap;

    .meta-radio__item + .meta-radio__item {
      margin-left: $t-space-unit-base;
    }
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: $t-space-unit-smaller;

    &__input {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 50%;
      box-shadow: 0 0 0 1px $t-color-neutral-06;
      flex-shrink: 0;
      height: calc(#{$t-space-unit-base} + 1px);
      transition: 0.2s border linear;
      width: calc(#{$t-space-unit-base} + 1px);

      &:checked {
        background: $t-color-neutral-06;
        border: 2px solid $t-color-neutral-01;
        box-shadow: 0 0 0 1px $t-color-neutral-06;
        height: calc(#{$t-space-unit-base} + 1px);
        width: calc(#{$t-space-unit-base} + 1px);
      }
    }

    &__disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &__label {
      align-items: center;
      cursor: pointer;
      display: flex;
      line-height: $t-type-size-line-height-paragraph;

      &__text {
        margin-left: $t-space-unit-smaller;
      }
    }
  }

  &__label {

    &--left {

      &.meta-radio__horizontal {
        justify-content: flex-end;
      }

      .meta-radio__validation {
        text-align: right;
      }

      .meta-radio__item {
        justify-content: flex-end;
      }

      .meta-radio__item__label {
        flex-direction: row-reverse;
      }

      .meta-radio__item__label__text {
        margin: 0 $t-space-unit-smaller 0 0;
      }
    }
  }

  &__validation {
    width: 100%;
  }
}
