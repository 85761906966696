.meta-checkbox {
  opacity: .85;

  &__input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1.25px solid $t-color-neutral-06;
    border-radius: $t-space-border-radius;
    cursor: pointer;
    flex-shrink: 0;
    height: calc(#{$t-space-unit-base});
    position: relative;
    transition: 0.2s background-color linear;
    width: calc(#{$t-space-unit-base});

    &:checked {
      background-color: $t-color-neutral-07;

      &:after {
        border: solid $t-color-neutral-01;
        border-width: 0 2px 2px 0;
        content: "";
        height: $t-space-unit-smaller;
        left: 35%;
        position: absolute;
        top: 10%;
        transform: rotate(45deg);
        width: $t-space-unit-smallest / 2;
      }
    }
  }

  &__disabled {
    opacity: .3;
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: $t-type-size-line-height-paragraph;
    opacity: .85;
    user-select: none;

    &__text {
      margin-left: $t-space-unit-smaller;
    }

    .meta-checkbox__label-left & {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .meta-checkbox__label__text {
        margin: 0 $t-space-unit-smaller 0 0;
      }
    }
  }

  &__validation {
    margin-top: $t-space-unit-smallest;
  }
}
