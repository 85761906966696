$transition-speed-slow: 1000ms;
$transition-speed: 500ms;

// sass-lint:disable-block indentation
%visible-tree {
  max-height: 5000px;
  opacity: 1;
  overflow: visible;
  transition: visibility $transition-speed-slow ease-in-out,
  max-height $transition-speed-slow ease-in-out,
  opacity $transition-speed-slow ease;
  visibility: visible;
}

// sass-lint:disable-block indentation
%hidden-tree {
  height: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: visibility $transition-speed-slow step-start,
              max-height $transition-speed-slow ease;
  visibility: hidden;
}

.meta-tree {

  .meta-btn {
    @extend %reset-button;

    &:hover {
      @extend %reset-button;
    }
  }
}

.meta-tree__list__item {
  width: 100%;

  .meta-tree__list {
    @extend %visible-tree;
  }

  &--link {
    display: inline-block;
    width: 100%;
  }

  &.is-expanded {

    > .meta-tree__list {
      @extend %visible-tree;
    }
  }

  &.is-collapsed {

    > .meta-tree__list {
      @extend %hidden-tree;
    }
  }
}

.meta-tree__list__item--link,
.meta-tree__list__item--trigger {
  align-items: center;
  display: flex;

  .meta-icon {
    flex-shrink: 0;
    margin-right: $t-space-unit-small;
  }
}

.meta-tree__list__item__content {
  align-items: center;
  display: flex;
  line-height: $t-type-size-line-height-micro;
  width: 100%;

  .meta-tree--level-1 & {
    margin-left: $t-space-unit-largest - $t-space-unit-smallest;
  }

  .meta-tree--level-2 & {
    padding-left: $t-space-unit-base;
  }
}
