
// Do not edit directly
// Generated on Mon, 03 Aug 2020 16:41:24 GMT

$t-color-base-dark-blue: #0051af;
$t-color-base-blue: #0068e1;
$t-color-base-lighter-blue: #3a93fa;
$t-color-base-lightest-blue: #cce1f9;
$t-color-base-dark-green: #04654e;
$t-color-base-green: #21846c;
$t-color-base-lightest-green: #d2ebe4;
$t-color-base-dark-yellow: #cc7300;
$t-color-base-yellow: #ecb322;
$t-color-base-lightest-yellow: #fbe7b5;
$t-color-base-dark-red: #b10404;
$t-color-base-red: #d92828;
$t-color-base-lighter-red: #e32a2a;
$t-color-base-lightest-red: #f9c1be;
$t-color-base-white: #ffffff;
$t-color-base-dark-blue-1: #f7f8f9;
$t-color-base-dark-blue-2: #ebecee;
$t-color-base-dark-blue-3: #dedfe3;
$t-color-base-dark-blue-4: #6e7584;
$t-color-base-dark-blue-5: #414b5f;
$t-color-base-dark-blue-6: #202b42;
$t-color-success-01: #d2ebe4;
$t-color-success-02: #21846c;
$t-color-success-03: #04654e;
$t-color-warning-01: #fbe7b5;
$t-color-warning-02: #ecb322;
$t-color-warning-03: #cc7300;
$t-color-critical-01: #f9c1be;
$t-color-critical-02: #d92828;
$t-color-critical-03: #b10404;
$t-color-neutral-01: #ffffff;
$t-color-neutral-02: #f7f8f9;
$t-color-neutral-03: #ebecee;
$t-color-neutral-04: #dedfe3;
$t-color-neutral-05: #6e7584;
$t-color-neutral-06: #414b5f;
$t-color-neutral-07: #202b42;
$t-color-primary-01: #cce1f9;
$t-color-primary-02: #3a93fa;
$t-color-primary-03: #0068e1;
$t-color-primary-04: #0051af;
$t-color-background: #ffffff;
$t-color-dark: #202b42;
$t-color-product-catalog: #9cabfa;
$t-color-product-customers: #fa74f4;
$t-color-product-money: #58d9cb;
$t-color-product-channels: #ffa235;
$t-color-product-supply-chain: #fbd92c;
$t-color-border-default: #dedfe3;
$t-space-xs: 4px;
$t-space-s: 8px;
$t-space-m: 12px;
$t-space-l: 16px;
$t-space-xl: 24px;
$t-space-xxl: 28px;
$t-space-xxxl: 36px;
$t-space-unit-smallest: 4px;
$t-space-unit-smaller: 8px;
$t-space-unit-small: 12px;
$t-space-unit-base: 16px;
$t-space-unit-large: 24px;
$t-space-unit-larger: 28px;
$t-space-unit-largest: 36px;
$t-space-border-radius: 4px;
$t-type-size-line-height-heading-1: 2.5rem;
$t-type-size-line-height-heading-2: 2.25rem;
$t-type-size-line-height-heading-3: 1.75rem;
$t-type-size-line-height-heading-4: 1.5rem;
$t-type-size-line-height-heading-5: 1.5rem;
$t-type-size-line-height-paragraph: 1.5rem;
$t-type-size-line-height-body-condensed: 1.25rem;
$t-type-size-line-height-sub: 1.25rem;
$t-type-size-line-height-micro: 1rem;
$t-type-size-heading-1: 2.0rem;
$t-type-size-heading-2: 1.625rem;
$t-type-size-heading-3: 1.25rem;
$t-type-size-heading-4: 1.0rem;
$t-type-size-heading-5: 0.875rem;
$t-type-size-paragraph: 1.0rem;
$t-type-size-sub: 0.8125rem;
$t-type-size-micro: 0.625rem;
$t-type-stack: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
$t-type-weight-regular: 400;
$t-type-weight-medium: 500;
$t-type-weight-bold: 700;
$t-type-weight-heading-1: 400;
$t-type-weight-heading-2: 500;
$t-type-weight-heading-3: 400;
$t-type-weight-heading-4: 500;
$t-type-weight-heading-5: 700;
$t-type-weight-paragraph: 400;
$t-type-weight-sub: 400;
$t-type-weight-micro: 400;