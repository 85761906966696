.meta-tabs {

  .meta-card__header {
    padding-bottom: 0;
  }

  .meta-card__title {
    margin-bottom: $t-space-unit-large;
  }
}

.meta-tab {
  @extend %reset-button;

  // a.meta-tab
  @include atRoot(a) {
    display: inline-block;
    text-decoration: none;
  }

  border: 1px solid transparent;
  border-bottom: $t-space-border-radius solid transparent;
  color: $t-color-neutral-05;
  cursor: pointer;
  font-size: $t-space-unit-base;
  padding-bottom: $t-space-unit-smaller;

  &:hover {
    color: $t-color-primary-03;
  }

  &:active {
    color: $t-color-primary-04;
  }

  &.active {
    color: $t-color-primary-03;
    position: relative;

    &::before,
    &::after {
      border-radius: $t-space-unit-smallest $t-space-unit-smallest 0 0;
      border-top: $t-space-unit-smallest solid $t-color-primary-03;
      bottom: 0;
      content: '';
      margin-bottom: -$t-space-unit-smallest;
      position: absolute;
      width: 100%;
    }

    &::before {
      left: -1px;
    }

    &::after {
      right: -1px;
    }
  }

  + .meta-tab {
    margin-left: $t-space-unit-large;
  }
}
