// sass-lint:disable class-name-format
.pagination-top,
.pagination-bottom {
  display: flex;
  justify-content: center;
  padding: $t-space-unit-smaller;
}

.pagination-bottom {
  border-top: 1px solid $t-color-border-default;
}

.-pagination,
.-pageSizeOptions {
  align-items: center;
  display: flex;
  width: 50%;

  .meta-input__input,
  .meta-select__control {
    margin-top: 0;
    text-align: center;
  }
}

.-pagination {
  justify-content: flex-end;

  &:last-child {
    justify-content: center;
  }

  + .-pageSizeOptions {
    margin-left: $t-space-unit-base;
  }

  .-previous,
  .-next {

    .meta-icon {
      fill: $t-color-primary-03;
    }

    &:disabled {

      .meta-icon {
        fill: $t-color-neutral-05;
      }
    }
  }

  .-pageJump {
    margin-right: $t-space-unit-smaller;
  }

  .-ofText {
    color: $t-color-neutral-05;
  }

  .-totalPages {
    margin-left: $t-space-unit-smaller;
  }

  .-pageInfo {
    align-items: center;
    display: flex;
    margin: 0 $t-space-unit-base;
  }
}

.-pageSizeOptions {
  justify-content: flex-start;

  p {
    color: $t-color-neutral-05;
    flex-shrink: 0;
  }

  .meta-select {
    flex-basis: $t-space-unit-small * 6;
    flex-shrink: 0;
    margin-left: $t-space-unit-smaller;
    z-index: 2;
  }
}

@media only screen and (max-width: $t-space-breakpoint-medium) {
  .-pagination {
    width: 40%;

    + .-pageSizeOptions {
      margin-left: $t-space-unit-smaller;
    }

    .-pageInfo {
      margin: 0 $t-space-unit-smaller;
    }

  }

  .-pageSizeOptions {
    width: 60%;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 40%;
    }
  }
}
