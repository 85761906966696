// sass-lint:disable class-name-format
.rt-thead {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  user-select: none;

  &.-header {

    .rt-th {

      &.-cursor-pointer {

        &:active {
          background-color: $t-color-neutral-03;
        }
      }
    }
  }

  .rt-th {
    @extend %reset-button;
    align-items: center;
    display: flex;
    text-align: left;

    .meta-table__sort-icon {
      fill: rgba($t-color-neutral-05, 0.5);
      height: 13px;
      margin-left: $t-space-unit-smaller;
      width: auto;
    }

    &.-sort-asc {

      .meta-table__sort-icon {
        margin-bottom: -$t-space-unit-smaller;
      }

      .arrow-down {
        display: none;
      }

      .arrow-up {
        fill: $t-color-neutral-07;
      }
    }

    &.-sort-desc {

      .meta-table__sort-icon {
        margin-top: -$t-space-unit-smaller;
      }

      .arrow-up {
        display: none;
      }

      .arrow-down {
        fill: $t-color-neutral-07;
      }
    }

    &:not(.-cursor-pointer) {

      .meta-table__sort-icon {
        display: none;
      }
    }
  }

  .rt-tr {
    background-color: $t-color-neutral-02; // not sure if correct
  }

  .rt-th,
  .rt-td {
    font-weight: $t-type-weight-heading-4;
    padding: $t-space-unit-smaller $t-space-unit-base;
    text-transform: uppercase;

    &.-cursor-pointer {
      cursor: pointer;
    }
  }

  .rt-th:focus {
    outline: none;
  }

  .rt-resizable-header {
    overflow: visible;
    position: relative;

    &:last-child {
      overflow: hidden;
    }
  }

  .rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // NO PIVOT STYLES HAVE BEEN ADDRESSED OTHER THAN VARIABLE ASSIGNMENTS
  .rt-header-pivot {
    border-right-color: $t-color-neutral-01;

    &:after,
    &:before {
      border: solid transparent;
      content: " ";
      height: 0;
      left: 100%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: 0;
    }

    &::after {
      border-color: $t-color-neutral-07;
      border-left-color: $t-color-neutral-01;
      border-width: 8px;
      margin-top: -8px;
    }

    &::before {
      border-color: $t-color-neutral-07;
      border-left-color: $t-color-neutral-01;
      border-width: 10px;
      margin-top: -10px;
    }
  }
}
