.meta-validation {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: $t-space-unit-small;

  &.error {
    .messaging-text {
      color: $t-color-critical-02;
    }

    svg {
      fill: $t-color-critical-02;
    }
  }

  &.warning {
    .messaging-text {
      color: $t-color-warning-03;
    }

    svg {
      fill: $t-color-warning-03;
    }
  }

  svg {
    margin: 0 $t-space-unit-smallest 0 0;
    vertical-align: middle;
  }

}

.meta-media-tile + .meta-validation {
  text-align: right;
}
