.rt-th,
.rt-td {
  flex: 1 0 0;
  text-overflow: ellipsis;
  transition: .3s ease;
  transition-property: width, min-width, padding, opacity;
  white-space: nowrap;

  &-hidden {
    border: 0;
    min-width: 0;
    opacity: 0;
    padding: 0;
    width: 0;
  }
}
