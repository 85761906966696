%reset-button {
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

%input {
  border: 1px solid $t-color-border-default;
  border-bottom: 1px solid $t-color-neutral-05;
  border-radius: $t-space-border-radius $t-space-border-radius 0 0;
  color: $t-color-neutral-07;
  margin-top: $t-space-unit-small;
  padding: $t-space-unit-base $t-space-unit-small;
  width: 100%;

  &::placeholder {
    color: $t-color-neutral-06;
    font-weight: $t-type-weight-regular;
  }

  &:not(:placeholder-shown) {
    padding: $t-space-unit-large $t-space-unit-small $t-space-unit-smaller $t-space-unit-small;

    + label {
      opacity: 1;
      transform: translate3d(0, -30%, 0); // use translate3d to improve animation performance
    }

    ~ div {
      opacity: 1;
    }
  }

  &:focus {
    border-bottom-color: $t-color-primary-03;
    border-bottom-width: 2px;
    outline: none;
    padding: $t-space-unit-large $t-space-unit-small 7px $t-space-unit-small;


   + label {
      color: $t-color-primary-03;
      opacity: 1;
      transform: translate3d(0, -30%, 0); // use translate3d to improve animation performance
    }
  }
}

%label {
  color: $t-color-neutral-06;
  font-size: $t-type-size-micro;
  font-weight: $t-type-weight-regular;
  left: $t-space-unit-smaller;
  line-height: $t-type-size-line-height-micro;
  opacity: 0;
  padding: 0 $t-space-unit-smallest;
  position: absolute;
  top: $t-space-unit-large;
  transition: transform 0.1s ease-out;
  z-index: 2;
}

%visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%validation-spacing {
  margin: 2px 0;
  padding: 0 $t-space-unit-small;
}