.meta-pill {
  $svg-transition-speed: 0.2s;

  align-items: center;
  background-color: rgba($t-color-neutral-04, 0.4);
  border: 0;
  border-radius: $t-space-unit-largest;
  cursor: pointer;
  padding: $t-space-unit-smaller $t-space-unit-base;
  transition: background-color 0.25s ease;
  width: fit-content;

  span {
    align-items: center;
    color: $t-color-neutral-07;
    display: flex;
    font-size: $t-type-size-paragraph;
    font-weight: $t-type-weight-paragraph;
    line-height: $t-type-size-line-height-body-condensed;
  }

  .meta-icon {
    box-shadow: 0 0 1px rgba($t-color-neutral-07, 0);
    fill: $t-color-neutral-06;
    margin-left: $t-space-unit-smaller;
    transition: transform $svg-transition-speed ease-in-out;
  }

  &:hover {
    background-color: rgba($t-color-neutral-04, 0.8);
    transition: background-color 0.25s ease;
  }

  &:focus {
    border-radius: $t-space-unit-largest;
    box-shadow: 0 0 $t-space-unit-smaller $t-color-primary-03;
    outline: none;
  }

  &.meta-pill--selected {
    background-color: $t-color-primary-03;

    span {
      color: $t-color-neutral-01;
    }

    .meta-icon {
      fill: rgba($t-color-neutral-01, 0.9);
      transform: rotate(45deg);
      transition: transform $svg-transition-speed ease-in-out;
    }
  }
}

// Applied to a parent grouping of MetaPill
// elements to allow for correct spacing
.meta-pill-group {
  padding: $t-space-unit-smaller;

  .meta-pill {
    margin: $t-space-unit-smallest;
  }
}
