// sass-lint:disable class-name-format, empty-line-between-blocks
@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  background-color: rgba($t-color-neutral-01, 0.7);
  bottom: 0;
  height: 5px;
  left: 0;
  opacity: 0.7;
  position: absolute;
  transform-origin: left;
  width: 100%;
  z-index: 9999;

  &--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform 0.2s;
  }

  &--rtl {
    left: initial;
    right: 0;
    transform-origin: right;
  }

  // &--default {
  //   background: linear-gradient(to right,
  //     #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  // }
}
