$max-width: 256px + $t-space-unit-large * 4;

@media only screen and (min-width: $t-space-breakpoint-small) {
  .meta-nav {
    position: sticky;
    top: 0;
    width: $max-width;

    &.meta-nav--is-expanded {
      max-width: $max-width;

      .meta-tree--level-0 {

        > .meta-tree__list__item {

          // HIDE THE TEXT
          // sass-lint:disable-block indentation
          span {
            max-height: $t-space-unit-large;
            overflow: hidden;
            transition: visibility $transition-speed linear,
                        color $transition-speed linear;
            visibility: visible;
          }
        }
      }
    }

    &.meta-nav--is-collapsed {
      max-width: 72px;

      .meta-nav__content,
      .meta-nav__footer {
        display: flex;
      }

      .meta-input__input {

        &::placeholder {
          visibility: hidden;
        }
      }

      .meta-tree__list__item {
        display: none;
      }

      .meta-tree--level-0 {

        > .meta-tree__list__item {
          display: list-item;

          // HIDE THE TEXT
          span {
            color: $t-color-neutral-07;
            max-height: $t-space-unit-large;
            transition: visibility 250ms linear, color $transition-speed linear;
            visibility: hidden;
          }
        }

        .meta-icon {
          margin: 0;
          transition: margin 300ms linear;
        }
      }
    }
  }

  .expand-collapse-button  {

    .meta-nav__header & {
      display: none;
    }

    .meta-nav__footer & {
      border-radius: 0;
      border-top: $nav-border;
      display: flex;
      padding: $t-space-unit-large;
    }

    .meta-nav--is-expanded & {
      justify-content: flex-end;

      .meta-icon {
        transform: rotate(360deg);
        transition: transform $transition-speed ease-in-out;
      }
    }

    .meta-nav--is-collapsed & {

      .meta-icon {
        transform: rotate(180deg);
      }
    }
  }
}
