%meta-group-layout {
  border-radius: $t-space-border-radius;
  display: inline-flex;
}

.meta-button-group {
  @extend %meta-group-layout;
  border: 1px solid $t-color-border-default;
  overflow: hidden;

  .meta-btn {
    border-radius: 0;

    &:first-of-type {
      border-bottom-left-radius: $t-space-border-radius;
      border-top-left-radius: $t-space-border-radius;
    }

    &:last-of-type {
      border-bottom-right-radius: $t-space-border-radius;
      border-top-right-radius: $t-space-border-radius;
    }
  }

  .meta-checkbox,
  .meta-radio__item {
    background-color: $t-color-neutral-01;

    &:not(:first-child) {
      border-left: 1px solid $t-color-border-default;
      margin: 0;
    }

    &__checked {
      background-color: $t-color-neutral-03;
    }

    &__input {
      @extend %visually-hidden;
    }

    &__label {
      padding: $t-space-unit-smaller;

      &__text {
        margin: 0;
      }
    }
  }

  .meta-icon {

    +.meta-checkbox__label__text,
    +.meta-radio__item__label__text {
      margin-left: $t-space-unit-smaller;
    }
  }

  .meta-radio__item {
    margin: 0;
  }

  &--icon-only {

    .meta-checkbox,
    .meta-radio__item {

      &__label {
        padding: $t-space-unit-small;
      }

      &__label__text {
        @extend %visually-hidden;
      }
    }
  }
}
