.meta-app {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: $t-space-breakpoint-small) {

  .meta-app {
    flex-direction: row;
  }
}
