.rt-tbody {
  display: flex;
  flex: 99999 1 auto;
  flex-direction: column;

  .rt-tr-group {
    border-bottom: 1px solid $t-color-border-default;

    &:last-child {
      border-bottom: 0;
    }
  }

  .meta-select__menu {
    z-index: 3;
  }

  .rt-expandable {
    cursor: pointer;
    text-overflow: clip;
  }
}
