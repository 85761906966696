%error-input {
  border-bottom-color: $t-color-critical-02;

    +label {
      color: $t-color-critical-03;
    }
}

%warning-input {
  border-bottom-color: $t-color-warning-02;

  +label {
    color: $t-color-warning-03;
  }
}

.input-invalid {

  &__error {
    input,
    textarea {
      @extend %error-input;
    }
  }

  &__warning {

    input,
    textarea {
      @extend %warning-input;
    }
  }
}
