// sass-lint:disable class-name-format, max-line-length
.Toastify__toast-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: max-content;
  z-index: 9999;

  &--top-left {
    left: $t-space-unit-base;
    top: 80px; //action bar height
  }

  &--top-center {
    left: 50%;
    margin-left: -160px;
    top: 80px; //action bar height
  }

  &--top-right {
    right: $t-space-unit-base;
    top: 80px; //action bar height
  }

  &--bottom-left {
    bottom: $t-space-unit-base;
    left: $t-space-unit-base;
  }

  &--bottom-center {
    bottom: $t-space-unit-base;
    left: 50%;
    margin-left: -160px;
  }

  &--bottom-right {
    bottom: $t-space-unit-base;
    right: $t-space-unit-base;
  }
}

.Toastify__toast {
  align-items: center;
  background: $t-color-neutral-01;
  border-radius: $t-space-border-radius;
  box-shadow: 0 0 $t-space-unit-small / 2 rgba($t-color-neutral-06, .25);
  cursor: pointer;
  direction: ltr;
  display: flex;
  justify-content: space-between;
  margin-bottom: $t-space-unit-base;
  overflow: hidden;
  position: relative;

  &--rtl {
    direction: rtl;
  }

  &-body {
    display: flex;
    flex: 1;
  }

  &--default,
  &--info {

    .meta-toast__status {
      background: $t-color-neutral-06;
    }
  }

  &--success {

    .meta-toast__status {
      background: $t-color-success-02;
    }
  }

  &--warning {

    .meta-toast__status {
      background: $t-color-warning-02;
    }
  }

  &--error {

    .meta-toast__status {
      background: $t-color-critical-02;
    }
  }

  &--default,
  &--info,
  &--success,
  &--error {

    .meta-toast__icon {
      fill: $t-color-neutral-01;
    }
  }
}

.meta-toast {

  &__status {
    align-items: center;
    display: flex;
    padding: $t-space-unit-base;
  }

  &__content {
    align-items: center;
    display: flex;
    padding: $t-space-unit-smaller;

    &--with-details {
      align-items: flex-start;
      flex-direction: column;

      .micro {
        margin: 0;
      }
    }
  }

  &__close-button.meta-btn { // sass-lint:disable-line force-element-nesting
    align-items: center;
    background-color: $t-color-neutral-03;
    border-radius: 50%;
    display: flex;
    height: $t-space-unit-base + $t-space-unit-smallest;
    justify-content: center;
    margin: $t-space-unit-smaller;
    width: $t-space-unit-base + $t-space-unit-smallest;

    &.icon-only {
      padding: 0;
    }

    &:hover {
      background-color: $t-color-neutral-02;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@import "./styles-progress.scss";
@import "./styles-keyframes.scss";
