.meta-input {
  position: relative;
  width: 100%;

  &__input {
    @extend %input;
  }

  &__label {
    @extend %label;
  }

  > .meta-validation {
    @extend %validation-spacing;
  }

  &.no-label {
    input {
      padding: $t-space-unit-base $t-space-unit-small;
    }
  }
}
