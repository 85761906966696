.meta-media-tile {
  background-color: $t-color-neutral-01;
  margin-top: $t-space-unit-base;

  &__media {
    flex-shrink: 0;
    margin-right: $t-space-unit-smaller;

    &-container {
      border: 1px solid $t-color-border-default;
      border-radius: $t-space-border-radius;
      height: $t-space-unit-largest; // match icon size
      width: $t-space-unit-largest; // match icon size
    }

    &--img {
      height: 100%;
      object-fit: scale-down;
      width: 100%;
    }

    &--video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    align-items: center;
    border: 1px solid $t-color-neutral-04;
    border-radius: 0 0 $t-space-border-radius $t-space-border-radius;
    display: flex;
    overflow: hidden;
    padding: $t-space-unit-base;
  }

  &__preview {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    margin-left: auto;

    &__status {
      margin: 0;

      .meta-media-tile--status-success & {
        color: $t-color-success-02;
      }

      .meta-media-tile--status-error & {
        color: $t-color-critical-02;
      }
    }

    &__remove-button {

      // sass-lint:disable-block force-element-nesting
      &.meta-btn.meta-link.icon-only {
        margin-left: $t-space-unit-base;
      }
    }

  }

  .meta-media-gallery--grid & {
    display: flex;
    justify-content: center;
    position: relative;

    &__content {
      border: 0;
      padding: 0;
    }

    &__media {

      &-container {
        height: $t-space-unit-base * 4;
        width: 100%;
      }
    }

    &__details {
      display: none;
    }

    &__actions {
      &__remove-button {
        border-radius: 100%;
        position: absolute;
        right: -$t-space-s;
        top: -$t-space-s;

        &.meta-btn {
          &.icon-only,
          &.icon-only:hover {
            background-color: $t-color-base-white;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.meta-media-gallery--grid {

  &.meta-media-gallery {

    &--m {

      .meta-media-tile__media-container {
        height: $t-space-unit-base * 8;
      }
    }

    &--l {

      .meta-media-tile__media-container {
        height: $t-space-unit-base * 16;
      }
    }

    &--xl {

      .meta-media-tile__media-container {
        height: $t-space-unit-base * 32;
      }
    }
  }
}
