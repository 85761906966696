.rt-resizer {
  bottom: 0;
  cursor: col-resize;
  display: inline-block;
  position: absolute;
  right: -18px;
  top: 0;
  width: $t-space-unit-largest;
  z-index: 10;
}

.rt-resizing {

  .rt-th,
  .rt-td {
    cursor: col-resize;
    transition: none;
    user-select: none;
  }
}
