h1 {
  color: $t-color-neutral-07;
  font-size: $t-type-size-heading-1;
  font-weight: $t-type-weight-heading-1;
  line-height: $t-type-size-line-height-heading-1;
}

h2 {
  color: $t-color-neutral-07;
  font-size: $t-type-size-heading-2;
  font-weight: $t-type-weight-heading-2;
  line-height: $t-type-size-line-height-heading-2;
}

h3 {
  color: $t-color-neutral-05;
  font-size: $t-type-size-heading-3;
  font-weight: $t-type-weight-heading-3;
  line-height: $t-type-size-line-height-heading-3;
}

h4 {
  color: $t-color-neutral-06;
  font-size: $t-type-size-heading-4;
  font-weight: $t-type-weight-heading-4;
  line-height: $t-type-size-line-height-heading-4;
}

h5,
h6 {
  color: $t-color-neutral-06;
  font-size: $t-type-size-heading-5;
  font-weight: $t-type-weight-heading-5;
  line-height: $t-type-size-line-height-heading-5;
  text-transform: uppercase;
}

p {
  color: $t-color-neutral-07;
  font-size: $t-type-size-paragraph;
  font-weight: $t-type-weight-paragraph;
  line-height: $t-type-size-line-height-paragraph;

  &.condensed {
    line-height: $t-type-size-line-height-body-condensed;
  }
}

sub,
.sub {
  color: $t-color-neutral-07;
  font-size: $t-type-size-sub;
  font-weight: $t-type-weight-sub;
  line-height: $t-type-size-line-height-sub;
}

.micro {
  color: $t-color-neutral-07;
  font-size: $t-type-size-micro;
  font-weight: $t-type-weight-micro;
  line-height: $t-type-size-line-height-micro;
}

a,
.meta-link {
  @extend %reset-button;
  color: $t-color-primary-03;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $t-color-primary-02;
  }

  &:active {
    color: $t-color-primary-04;
  }
}

.visually-hidden {
  @extend %visually-hidden;
}
