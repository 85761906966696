.meta-progress {
  background-color: $t-color-neutral-04;
  border-radius: $t-space-border-radius;
  height: $t-space-unit-smaller;
  margin-top: $t-space-unit-base;
  overflow: hidden;

  &--bar {
    background-color: $t-color-neutral-06;
    border-radius: 0 $t-space-border-radius $t-space-border-radius 0;
    display: block;
    height: 100%;
  }

  &--success {
    .meta-progress--bar {
      background-color: $t-color-success-02;
    }
  }

  &--error {
    .meta-progress--bar {
      background-color: $t-color-critical-02;
    }
  }

  &--with-media-tile {
    border-radius: $t-space-border-radius $t-space-border-radius 0 0;
  }

  + .meta-media-tile {
    margin-top: 0;

    .meta-media-tile__content {
      border-top: 0;
    }
  }

  &--success,
  &--error {

    .meta-progress--bar {
      border-radius: 0;
    }

    + .meta-media-tile {
      margin-top: -$t-space-unit-smallest;
    }
  }

  .meta-media-gallery--grid & {
    display: none;
  }
}
