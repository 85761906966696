// Base styles pulled from "~react-table/react-table.css"; at v6
// Import order based on original react-table order

// sass-lint:disable class-name-format
.meta-table {
  border: 1px solid $t-color-border-default;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  position: relative;
  width: 100%;

  .rt-table {
    align-items: stretch;
    border-collapse: collapse;
    display: flex;
    flex: auto 1;
    flex-direction: column;
    width: 100%;
  }

  @import "./styles-thead.scss";
  @import "./styles-tbody.scss";
  @import "./styles-tr-group.scss";

  .rt-tr {
    display: inline-flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
  }

  @import "./styles-th-td.scss";
  @import "./styles-expander.scss";
  @import "./styles-resize.scss";

  .rt-tfoot {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }

  &.-selectable {
    overflow: visible;
  }

  &.-striped {

    .rt-tr {

      &.-even {
        background-color: $t-color-neutral-02;
      }
    }

    &.-highlight {

      .rt-tbody {

        .rt-tr:not(.-padRow):hover {
          background-color: $t-color-neutral-03;
        }
      }
    }
  }

  &.-highlight {

    .rt-tbody {

      .rt-tr:not(.-padRow):hover {
        background-color: $t-color-neutral-02;
      }
    }
  }


  @import "./styles-pagination.scss";

  .rt-noData {
    background: rgba($t-color-neutral-01, .8);
    color: $t-color-neutral-07;
    font-size: $t-type-size-paragraph;
    font-weight: $t-type-weight-paragraph;
    left: 50%;
    line-height: $t-type-size-line-height-paragraph;
    pointer-events: none;
    position: absolute;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  @import "./styles-loading.scss";
}


// variants based on using meta-table
.meta-tabs--table-header {

  .meta-tab {
    font-weight: $t-type-weight-heading-4;
  }

  .meta-card__content {
    padding: 0;

    .meta-table {
      border: 0;
    }
  }
}


@media only screen and (max-width: $t-space-breakpoint-medium) {
  .meta-table {

    .rt-noData {
      text-align: center;
      width: 100%;
    }
  }
}
