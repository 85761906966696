.meta-toggle {
  $toggle-height: $t-space-unit-large;

  background-color: $t-color-neutral-05;
  border: none;
  border-radius: $t-space-unit-largest;
  display: inline-flex;
  height: $toggle-height;
  padding: 0;
  transition: all 0.2s;
  width: $t-space-unit-largest + $t-space-unit-small; // 48px

  &::after {
    $circleMargin: 2px;
    background-color: $t-color-neutral-01;
    border-radius: 50%;
    content: '';
    height: $toggle-height - ($circleMargin * 2);
    margin: 0 $circleMargin;
    transition: all 0.2s;
    width: $toggle-height - ($circleMargin * 2);
  }

  &[aria-checked="true"] {
    background-color: $t-color-neutral-06;

    &::after {
      margin-left: $t-space-unit-large + ($t-space-unit-smaller / 4); // 26px
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;

    &::after {
      box-shadow: none;
    }
  }

  &.primary {
    &[aria-checked="true"] {
      background-color: $t-color-primary-02;
    }
  }

  &.warning {
    &[aria-checked="true"] {
      background-color: $t-color-warning-02;
    }
  }

  &.success {
    &[aria-checked="true"] {
      background-color: $t-color-success-02;
    }
  }

  &.critical {
    &[aria-checked="true"] {
      background-color: $t-color-critical-02;
    }
  }
}
