.meta-btn {
  @extend %reset-button;
  align-items: center;
  background-color: $t-color-neutral-04;
  border-radius: $t-space-border-radius;
  color: $t-color-neutral-06;
  cursor: pointer;
  display: inline-flex;
  font-weight: $t-type-weight-medium;
  line-height: $t-type-size-line-height-body-condensed;
  padding: $t-space-unit-smaller $t-space-unit-base;
  text-align: left;

  &:hover {
    background-color: $t-color-neutral-03;
  }

  &:active {
    background-color: $t-color-neutral-05;
  }

  .meta-icon {
    fill: $t-color-neutral-06;
    pointer-events: none;
  }

  // VARIANTS
  &.primary {
    background-color: $t-color-primary-03;
    color: $t-color-base-white;

    &:hover {
      background-color: $t-color-primary-02;
    }

    &:active {
      background-color: $t-color-primary-04;
    }

    .meta-icon {
      fill: $t-color-base-white;
    }
  }


  &.critical {
    background-color: $t-color-critical-02;
    color: $t-color-base-white;

    &:hover {
      background-color: scale-color($t-color-critical-02, $lightness: 12%);
    }

    &:active {
      background-color: scale-color($t-color-critical-02, $lightness: -12%);
    }

    .meta-icon {
      fill: $t-color-base-white;
    }
  }

  &.outline {
    background-color: unset;
    border: 1px solid $t-color-neutral-04;
    color: $t-color-neutral-06;

    &:hover {
      background-color: $t-color-neutral-02;
    }

    &:active {
      background-color: $t-color-neutral-03;
      border-color: $t-color-neutral-05;
      color: $t-color-neutral-07;
    }

    .meta-icon {
      fill: $t-color-neutral-07;
    }
  }

  &.outline-primary {
    background-color: unset;
    border: 1px solid $t-color-primary-03;
    color: $t-color-primary-03;

    &:hover {
      background-color: rgba($t-color-primary-01, 0.4);
    }

    &:active {
      background-color: $t-color-primary-01;
      border-color: $t-color-primary-04;
      color: $t-color-primary-04;
    }

    .meta-icon {
      fill: $t-color-primary-04;
    }
  }

  &.outline-critical {
    background-color: unset;
    border: 1px solid $t-color-critical-02;
    color: $t-color-critical-02;

    &:hover {
      background-color: rgba($t-color-critical-01, 0.4);
    }

    &:active {
      background-color: $t-color-critical-01;
      border-color: $t-color-critical-03;
      color: $t-color-critical-03;
    }

    .meta-icon {
      fill: $t-color-critical-03;
    }
  }

  &.s {
    padding: $t-space-unit-smallest $t-space-unit-base;
  }

  &.l {
    padding: $t-space-unit-small $t-space-unit-large;
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    background-color: rgba($t-color-neutral-04, .4);
    color: rgba($t-color-neutral-06, .4);

    svg {
      fill: rgba($t-color-neutral-06, .4);
    }

    .meta-icon {
      fill: rgba($t-color-neutral-06, .4);
    }

    // sass-lint:disable-block force-pseudo-nesting (for cleanliness)
    &:hover,
    &.primary:hover,
    &.active:hover {
      background-color: rgba($t-color-neutral-04, .4);
      color: rgba($t-color-neutral-06, .4);
      cursor: default;
    }
  }

  // as anchor
  @at-root a#{&} {
    color: $t-color-neutral-06;
    text-decoration: none;

    &:disabled,
    &.disabled,
    &[disabled] {
      pointer-events: none;
    }

  }

  &.s {
    font-size: $t-type-size-sub;
  }

  // ICON
  .meta-icon {
    flex-shrink: 0;
  }

  &.with-icon {

    &.s {
      .meta-icon {
        margin-right: $t-space-unit-smaller;
      }
    }

    &.m {
      .meta-icon {
        margin-right: ($t-space-unit-smaller + $t-space-unit-small) / 2; //10px
      }
    }

    &.l {
      .meta-icon {
        margin-right: $t-space-unit-small;
      }
    }

    .meta-icon {
      margin-right: $t-space-unit-small;

      &.icon-loading {
        margin: initial;
      }
    }
  }

  &.icon-right {
    flex-direction: row-reverse;

    &.s {
      .meta-icon {
        margin: 0 0 0 $t-space-unit-smaller;
      }
    }

    &.m {
      .meta-icon {
        margin: 0 0 0 ($t-space-unit-smaller + $t-space-unit-small) / 2; //10px
      }
    }

    &.l {
      .meta-icon {
        margin: 0 0 0 $t-space-unit-small;
      }
    }

    .meta-icon {

      &.icon-loading {
        margin: initial;
      }
    }
  }

  &.icon-only {
    padding: $t-space-unit-smaller;

    &.m {
      padding: ($t-space-unit-smaller + $t-space-unit-small) / 2; //10px
    }

    &.l {
      padding: $t-space-unit-small;
    }

    &.meta-link {
      @extend %reset-button;

      &:hover {
        @extend %reset-button;

        &.m {
          padding: ($t-space-unit-smaller + $t-space-unit-small) / 2; //10px
        }

        &.l {
          padding: $t-space-unit-small;
        }
      }
    }
  }

  &.no-background {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  .meta-spinner__label {

    @include atRoot('.primary') {
      color: $t-color-base-white;
    }

    @include atRoot('.critical') {
      color: $t-color-base-white;
    }

    color: $t-color-neutral-06;
    line-height: $t-type-size-line-height-body-condensed;
  }

  .is-loading {
    cursor: not-allowed;
  }
}

// Specific to the button context
.meta-link {
  line-height: $t-type-size-line-height-body-condensed;

  // SIZE
  &.s {
    font-size: $t-type-size-sub;
  }
}

.meta-icon--button {

  // Icon base size in buttons is larger
  &.meta-icon.meta-icon--base { // sass-lint:disable-line force-element-nesting
    height: $t-space-unit-base + $t-space-unit-smallest;
    width: $t-space-unit-base + $t-space-unit-smallest;
  }
}
