@media only screen and (max-width: $t-space-breakpoint-small) {
  .meta-nav {
    width: 100%;
    z-index: 5;

    &.meta-nav--is-expanded {
      position: fixed;
    }

    &.meta-nav--is-collapsed {
      max-height: 72px;

      .meta-nav__search,
      .meta-nav__content,
      .meta-nav__footer {
        display: none;
      }
    }
  }

  .expand-collapse-button {

    .meta-nav__header & {
      display: inline-block;
    }

    .meta-nav__footer & {
      display: none;
    }
  }
}
