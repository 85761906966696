.rt-expander {
  color: transparent;
  display: inline-block;
  margin: 0;
  position: relative;

  &:after {
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    content: '';
    cursor: pointer;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 0;
  }

  &.-open:after {
    transform: translate(-50%, -50%) rotate(0);
  }
}
