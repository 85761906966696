.meta-expandable-panel {
  border-bottom: 1px solid $t-color-neutral-04;

  summary::-webkit-details-marker {
    display: none;
  }

  .meta-expandable-panel__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: $t-space-unit-largest + $t-space-unit-small;
    padding: 0 $t-space-unit-base;
    user-select: none;

    &.arrow-position-right {
      flex-direction: row-reverse;
      justify-content: space-between;

      .panel-collapse-icon {
        margin-left: $t-space-unit-smaller;
        margin-right: 0;
      }
    }

    &:focus {
      outline: none;
    }

    .meta-expandable-panel__header__details {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;

      .meta-expandable-panel__header__details__title {
        color: rgba($t-color-neutral-07, 0.85);
        margin-right: $t-space-unit-base;
      }
    }

    .panel-collapse-icon {
      align-self: center;
      fill: rgba($t-color-neutral-07, 0.85);
      margin-right: $t-space-unit-smaller;
      transition: 0.02s;

      &.up {
        transform: rotate(180deg);
      }

      &.right {
        transform: rotate(-90deg);
      }

      &.left {
        transform: rotate(90deg);
      }
    }
  }

  .meta-expandable-panel__body {
    padding-bottom: $t-space-unit-base;
    padding-left: $t-space-unit-largest + 6px;
    padding-right: $t-space-unit-base;
    padding-top: $t-space-unit-base;
  }

  &.is-expanded {
    border-color: rgba($t-color-primary-03, 0.35);

    .meta-expandable-panel__header {
      .meta-expandable-panel__header__details__title {
        color: $t-color-primary-03;
      }

      .panel-collapse-icon {
        fill: $t-color-primary-03;
      }
    }
  }

  &:hover {
    .meta-expandable-panel__header {
      .panel-collapse-icon {
        fill: rgba($t-color-primary-03, 0.45);
      }
    }
  }
}
