.meta-media-gallery {
  margin-top: $t-space-unit-base;

  .dnd-media-tile {
    align-items: center;
    cursor: move;
    display: flex;
    justify-content: space-between;
    margin-top: $t-space-unit-base;

    &.dragging {
      opacity: 0;
    }

    &.active {
      .meta-media-tile {
        background-color: rgba($t-color-base-lightest-blue, 0.5);
      }

      .reorder-icon {
        opacity: 0;
      }
    }

    .meta-media-tile {
      margin: 0;
      width: 100%;
    }

    .reorder-icon {
      fill: $t-color-base-dark-blue-3;
      margin-left: $t-space-unit-base;
    }
  }

  &--grid {
    display: grid;
    grid-gap: $t-space-unit-base;
    text-align: center;

    .dnd-media-tile {
      .meta-media-tile {
        min-height: 100%;
      }
    }

    @mixin audioWidth($width) {
      .meta-media-tile,
      .dnd-media-tile {
        audio {
          width: $width;
        }
      }
    }

    &.meta-media-gallery--s {
      @include audioWidth($t-space-unit-base * 4);

      grid-template-columns: repeat(auto-fill, #{$t-space-unit-base * 4});
    }

    &.meta-media-gallery--m {
      @include audioWidth($t-space-unit-base * 8);

      grid-template-columns: repeat(auto-fill, #{$t-space-unit-base * 8});
    }

    &.meta-media-gallery--l {
      @include audioWidth($t-space-unit-base * 16);

      grid-template-columns: repeat(auto-fill, #{$t-space-unit-base * 16});
    }

    &.meta-media-gallery--xl {
      @include audioWidth($t-space-unit-base * 32);

      grid-template-columns: repeat(auto-fill, #{$t-space-unit-base * 32});
    }
  }
}

.gallery-modal {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  width: 100%;

  &__header {
    align-items: center;
    border-bottom: 1px solid $t-color-border-default;
    display: flex;
    justify-content: space-between;
    min-height: 64px;
    padding: 0 $t-space-unit-large;
  }

  &__title {
    color: $t-color-neutral-07;
    font-size: 22px;
    margin: 0;
  }

  &__close-button {
    margin-right: -10px;
  }

  &__body {
    padding: $t-space-unit-largest;
  }

  &__details {
    margin: 0 auto;
    width: 500px;
  }

  &__file-name {
    margin: 0;
  }

  &__file-specifics {
    color: rgba($t-color-dark, 0.65);
  }

  &__media {
    align-items: center;
    border: 1px solid $t-color-border-default;
    border-radius: $t-space-border-radius;
    display: flex;
    height: 500px;
    justify-content: center;
    margin: $t-space-unit-largest auto;
    max-width: 500px;
    width: 100%;

    img {
      height: 100%;
    }

    img,
    video {
      object-fit: contain;
      width: 100%;
    }
  }

  &__controls {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: -10px 0;
  }

  &__image-count {
    padding: 0 $t-space-unit-smaller;
  }

  @media screen and (max-height: 968px) {
    height: 95vh;
    left: 50%;
    margin: 0 auto;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
