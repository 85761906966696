@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.meta-spinner {
  align-items: center;
  display: flex;

  &__label {
    &--left {
      flex-direction: row-reverse;
      justify-content: center;

      .meta-spinner__label {
        margin-right: $t-space-unit-smaller;
      }
    }

    &--right {
      flex-direction: row;
      justify-content: center;

      .meta-spinner__label {
        margin-left: $t-space-unit-smaller;
      }
    }

    &--top {
      flex-direction: column-reverse;

      .meta-spinner__label {
        margin-bottom: $t-space-unit-smaller;
      }
    }

    &--bottom {
      flex-direction: column;

      .meta-spinner__label {
        margin-top: $t-space-unit-smaller;
      }
    }
  }

  &__icon {
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: linear;
  }

  &__label {
    color: $t-color-neutral-07;
    line-height: $t-space-unit-large;
  }
}
