.meta-tag {
  align-items: center;
  display: flex;
  width: 100%;

  &__indicator {
    color: $t-color-neutral-04;
    margin-right: $t-space-s;

    &.critical {
      color: $t-color-critical-02;
    }

    &.information {
      color: $t-color-primary-03;
    }

    &.success {
      color: $t-color-success-02;
    }

    &.warning {
      color: $t-color-warning-02;
    }
  }

  &__text {
    font-size: $t-type-size-paragraph;
    font-weight: $t-type-weight-paragraph;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
