.-loading {
  background: rgba($t-color-neutral-01, .8);
  bottom: 0;
  display: block;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;

  > div {
    display: block;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-52%);
    width: 100%;
  }

  &.-active {
    opacity: 1;
    pointer-events: all;
    z-index: 2;

    > div {
      transform: translateY(50%);
    }
  }
}
