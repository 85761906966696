$card-border: 1px solid $t-color-neutral-04;

@mixin cardBorder($header-color) {
  // sass-lint:disable-block indentation
  background-image:
    linear-gradient($t-color-base-white, $t-color-base-white),
    linear-gradient(to bottom,
                  $header-color,
                  $header-color 9px,
                  $t-color-neutral-04 8px,
                  $t-color-neutral-04 8px);
}

.meta-card {
  background-color: $t-color-background;
  border: $card-border;
  border-radius: $t-space-border-radius $t-space-border-radius 0 0;
  overflow: hidden;

  h3 {
    color: $t-color-neutral-06;
    line-height: $t-type-size-line-height-body-condensed;
    margin: 0;
  }
}

.meta-card--with-spine {
  @include cardBorder($t-color-neutral-04);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;

  &::before {
    background-color: $t-color-neutral-04;
    content: '';
    display: block;
    height: $t-space-unit-smaller;
    left: 0;
    top: 0;
    width: calc(100% + 2px);
  }

  .meta-card__header {
    margin-top: -$t-space-unit-smaller;
  }

  &--spine-primary {
    @include cardBorder($t-color-primary-03);

    &::before {
      background-color: $t-color-primary-03;
    }
  }

  &--spine-critical {
    @include cardBorder($t-color-critical-02);

    &::before {
      background-color: $t-color-critical-02;
    }
  }

    &--spine-success {
    @include cardBorder($t-color-success-02);

    &::before {
      background-color: $t-color-success-02;
    }
  }
}


.meta-card__header,
.meta-card__content {
  padding: $t-space-unit-large + 2px $t-space-unit-large;
}

.meta-card__header {

  & + .meta-card__content {
    border-top: $card-border;
    min-height: calc(100% - 72px);
    overflow: hidden;
  }
}

.meta-card__title--with-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
