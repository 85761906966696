@import "~react-datetime/css/react-datetime.css";

%cell-hover {
  background-color: $t-color-primary-01;
  color: $t-color-primary-03;
  font-weight: $t-type-weight-medium;
}

// sass-lint:disable class-name-format
.meta-datepicker {
  position: relative;

  .meta-input__input {

    &:not(:placeholder-shown) ~ label {
      opacity: 1;
      transform: translate3d(0, -30%, 0);
    }
  }

  .rdtPicker {
    margin-bottom: $t-space-unit-largest;
    padding: $t-space-unit-smaller $t-space-unit-small;
    width: auto;

    .rdtSwitch {
      border-bottom: 0;
      border-radius: $t-space-border-radius;
      height: auto;
      padding: $t-space-unit-smaller;
      width: auto;
    }

    .rdtPrev,
    .rdtNext {
      border-bottom: 0;
      border-radius: $t-space-border-radius;
      height: auto;
      line-height: .5;
      padding: $t-space-unit-smaller $t-space-unit-small;
    }
  }

  .rdtDays {
    table {
      width: auto;
    }

    thead {
      display: flex;
      flex-direction: column;

      tr {
        display: flex;
        justify-content: space-between;
      }

      th {
        align-items: center;
        display: flex;
        font-weight: 400;
        justify-content: center;
      }
    }

    tbody {
      tr {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
    }

    .rdtDay {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: $t-space-unit-largest;
      justify-content: center;
      position: relative;
      width: $t-space-unit-largest;

      &:hover {
        @extend %cell-hover;
      }
    }


    td.rdtToday { // sass-lint:disable-line force-element-nesting, no-qualifying-elements, max-line-length

      &::before {
        border: 0;
        content: none;
        top: 0;
      }
    }

    .rdtActive { // beginning and end
      background-color: $t-color-primary-03;

      &:hover {
        background-color: $t-color-primary-02;
        color: $t-color-neutral-01;
      }
    }
  }

  .rdtMonths,
  .rdtYears {
    tbody {
      tr {
        display: grid;
        grid-gap: $t-space-unit-smallest;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    td {
      height: $t-space-unit-largest;
      padding: $t-space-unit-smaller $t-space-unit-smallest;
      width: $t-space-unit-largest;


      &:hover {
        @extend %cell-hover;
      }

      &.rdtActive {
        background-color: $t-color-primary-03;

        &:hover {
          background-color: $t-color-primary-02;
          color: $t-color-neutral-01;
        }
      }
    }
  }

  .rdtYears {

    td {
      width: $t-space-unit-largest * 2;
    }
  }

  .meta-validation {
    @extend %validation-spacing;
  }
}

.meta-datepicker--range {
  display: flex;
  flex-wrap: wrap;

  .meta-datepicker {

    &__input {
      width: 50%;
    }
  }

  .meta-datepicker__start {

    .meta-input__input {
      border-radius: $t-space-border-radius 0 0 $t-space-border-radius;
      border-right: 0;

      &:focus {
        outline: 0;
      }
    }
  }

  .meta-datepicker__end {

    .meta-input__input {
      border-left: 0;
      border-radius: 0 $t-space-border-radius $t-space-border-radius 0;
      text-align: right;;

      &:focus {
        outline: 0;
      }
    }

    .rdtPicker {
      right: 0;
    }
  }

  .in-range {
    background-color: $t-color-primary-01;

    &.rdtDay {
      border-radius: 0;

      &:hover {
        background: radial-gradient($t-color-neutral-01 70%, $t-color-primary-01 75%); // sass-lint:disable-line max-line-length
      }
    }
  }

  .in-range,
  .rdtActive {
    margin: $t-space-unit-smallest / 2 0;
  }

  .rdtActive {

    &.is-start:not(.is-end)::after,
    &.is-end:not(.is-start)::before {
      background-color: $t-color-primary-01;
      content: " ";
      height: $t-space-unit-largest;
      position: absolute;
      width: $t-space-unit-base;
      z-index: -1;
    }

    &.is-start:not(.is-end) {

      &::after {
        right: 0;
      }
    }

    &.is-end:not(.is-start) {

      &::before {
        left: 0;
      }
    }
  }

  .end-date-dash {
    bottom: 15%;
    position: absolute;
  }

  .meta-validation {
    width: 100%;
  }
}
