$nav-collapsed-width: 72px;
$nav-expanded-width: 352px;

.meta-content-layout {
  width: calc(100vw - #{$nav-collapsed-width});
}

.meta-app--is-nav-expanded {
  .meta-content-layout {
    width: calc(100vw - #{$nav-expanded-width});
  }
}

.meta-content-floor {
  height: calc(100vh - #{$t-space-unit-largest * 2 + $t-space-unit-smaller});
  max-width: 100%;
  overflow: auto;
  padding: $t-space-unit-large;
}

@media only screen and (max-width: $t-space-breakpoint-small) {
  .meta-content-layout {
    position: absolute;
    top: $t-space-unit-largest * 2;
    z-index: 1;
  }

  .meta-content-floor {
    height: calc(100vh - 144px);
    padding: $t-space-unit-small;
  }
}
